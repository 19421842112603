
import {
  getCategories,
  getCategoryById,
  deleteCategory,
  saveCategory
} from '@/api/product-categories.api'
// import { ToastLife, ToastSeverities } from '../../models/components/toast/enums'
import { ProductCategory } from '@/models/www/ProductCategory'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { ToastLife } from '@/models/components/toast/enums'
import { useConfirm } from 'primevue/useconfirm'
import Textarea from 'primevue/textarea'

export default defineComponent({
  name: 'ProductCategoriesListView',
  components: {
    DataTable,
    Textarea
  },
  setup() {
    const emptyProductCategory: () => ProductCategory = () => ({
      name: '',
      key: '',
      enable: true,
      description: ''
    })

    const isValidCategoryData = computed(() =>
      Boolean(
        editingCategory.value.name?.trim() && editingCategory.value.key?.trim()
      )
    )
    const toast = useToast()
    const loading = ref<boolean>(false)
    const categories = ref<Array<ProductCategory>>([])
    const showEditDialog = ref<boolean>(false)
    const editingCategory = ref<ProductCategory>(emptyProductCategory())
    const confirm = useConfirm()

    async function updateCategoryList(): Promise<void> {
      loading.value = true
      categories.value = await getCategories()
      loading.value = false
    }

    const addNew = async () => {
      editingCategory.value = emptyProductCategory()

      showEditDialog.value = true
    }

    const onEdit = async (category: ProductCategory) => {
      if (!category.key) return
      const fetchedCategory = await getCategoryById(category.key)
      if (!fetchedCategory) return
      editingCategory.value = fetchedCategory
      showEditDialog.value = true
    }

    const confirmCategoryDeletion = (category: ProductCategory) =>
      new Promise<boolean>((resolve) =>
        confirm.require({
          group: 'global',
          message: `¿Estás seguro de eliminar la Categoría ${category.name}?`,
          header: '¡Alto ahí!',
          icon: 'pi pi-exclamation-triangle',
          accept: () => resolve(true),
          reject: () => resolve(false)
        })
      )

    const onDelete = async (category: ProductCategory) => {
      try {
        if (!(await confirmCategoryDeletion(category))) return

        await deleteCategory(category)

        toast.add({
          severity: 'success',
          summary: 'Categoría eliminada',
          detail: `La Categoría ${category.name} ha sido eliminada`,
          life: ToastLife.Default
        })

        await updateDeps()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'Error al eliminar la Categoría',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
    }

    const onSaveCategory = async () => {
      loading.value = true
      const category = editingCategory.value
      try {
        await saveCategory(category)
        await updateDeps()
        toast.add({
          severity: 'success',
          summary: 'Categoría guardada',
          detail: `La Categoría ${category.name} ha sido guardada`,
          life: ToastLife.Default
        })
        showEditDialog.value = false
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error al guardar la Categoría',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
      loading.value = false
    }

    const onCancelEditDialog = async () => {
      showEditDialog.value = false
    }

    const updateDeps = async () => {
      await updateCategoryList()
    }

    const setLoadingValue = (value: boolean) => {
      loading.value = value
    }

    onMounted(async () => {
      await updateDeps()
    })

    return {
      addNew,
      categories,
      editingCategory,
      isValidCategoryData,
      loading,
      onCancelEditDialog,
      onEdit,
      onDelete,
      onSaveCategory,
      showEditDialog,
      setLoadingValue
    }
  }
})
