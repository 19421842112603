import { Api } from '@/api/api'
import { BaseResponse } from '@/models/BaseResponse'
import { ProductCategory } from '@/models/www/ProductCategory'

const apiGw = process.env.API_GATEWAY

async function getCategories(): Promise<ProductCategory[]> {
  const res = Api.get<BaseResponse<ProductCategory[]>>(
    `${apiGw}/ecommerce/api/v1/categories`
  )
  const categories = res.then((r) => r.data)
  return categories
}

async function getCategoryById(key: string): Promise<ProductCategory> {
  const res = Api.get<BaseResponse<ProductCategory>>(
    `${apiGw}/ecommerce/api/v1/categories/${key}`
  )
  const data = res.then((r) => r?.data)
  return data
}

async function deleteCategory(category: ProductCategory): Promise<void> {
  if (!category.id) return
  await Api.del<BaseResponse<ProductCategory> | null>(
    `${apiGw}/ecommerce/api/v1/categories/${category.id}`,
    category
  )
}

async function saveCategory(
  productCategory: ProductCategory
): Promise<ProductCategory> {
  if (!productCategory.id) delete productCategory.id
  if (productCategory.id) {
    const res = Api.put<BaseResponse<ProductCategory>>(
      `${apiGw}/ecommerce/api/v1/categories/${productCategory.id}`,
      productCategory
    )
    const data = res.then((r) => r.data)
    return data
  }

  const res = Api.post<BaseResponse<ProductCategory>>(
    `${apiGw}/ecommerce/api/v1/categories`,
    productCategory
  )
  const data = res.then((r) => r.data)
  return data
}

export { getCategories, getCategoryById, deleteCategory, saveCategory }
